import * as React from 'react';
import fetch from 'isomorphic-fetch';
import {
  Provider as ClientProvider,
  createClient,
  debugExchange,
  defaultExchanges,
} from 'urql';
import { devtoolsExchange } from '@urql/devtools';

import '@seed-design/stylesheet/global.css';
import 'gatsby-plugin-karrotbridge/global.css';

import './Layout.css';

const client = createClient({
  url: process.env.GATSBY_KARROTMINI_MARKETPLACE_API_ENDPOINT || 'https://api-cdn.karrotmini.dev',
  exchanges: [
    debugExchange,
    devtoolsExchange,
    ...defaultExchanges,
  ],
  suspense: true,
  fetch,
});

const Layout: React.FC = ({
  children,
}) => {
  React.useEffect(() => {
    const el = document.documentElement;
    el.dataset.seed = '';

    const prefersLight = window.matchMedia('(prefers-color-scheme: light)');
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

    if (prefersLight.matches) {
      if ('addEventListener' in prefersLight) {
        prefersLight.addEventListener('change', apply);
      } else if ('addListener' in prefersLight) {
        prefersLight.addListener(apply);
      }
    } else if (prefersDark.matches) {
      if ('addEventListener' in prefersDark) {
        prefersDark.addEventListener('change', apply);
      } else if ('addListener' in prefersDark) {
        prefersDark.addListener(apply);
      }
    }

    function apply() {
      el.dataset.seedScaleColor = prefersDark.matches ? 'dark' : 'light';
    }

    apply();
  });

  return (
    <ClientProvider value={client}>
      {children}
    </ClientProvider>
  );
};

export default Layout;
